/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  Container,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Box,
  CircularProgress,
  Divider,
  Button,
  IconButton,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LanguageIcon from "@mui/icons-material/Language";
import moment from "moment";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { Colors } from "../common";
import { client } from "../Contentful/Client";
import RichText from "../Contentful/RichText";
import PageTitle from "../Components/PageTitle";
import Header from "../Components/Header";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import assets from "../assets";

const SingleBlog = () => {
  const location = useLocation();
  // const id = location.state?.id || "No ID found";
  // const { id, title } = useParams();
 
  // console.log(id, "id");

  const { slug } = useParams(); 
  
  const id = slug.split("-").pop();
  
  const [loading, setLoading] = useState(true);
  const [blog, setBlog] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const entries = await client.getEntries({ content_type: "blog" });

        let a = entries.items;
        a.map((item) => {
          if (item.fields.blogId == id) {
            setBlog(item.fields);
          }
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    fetchData();
  }, [loading]);

  if (loading) {
    return (
      <p
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "25%",
        }}
      >
        <CircularProgress style={{ color: "#f68f5c" }} />
      </p>
    );
  }

  const formattedDate = moment(blog?.blogPostDate).format("MMMM DD, YYYY");
 
  return (
    <>
      <PageTitle
        title={
          blog?.blogTitle ||
          "GatikAI Technologies Blog | Insights on AI, IoT, Web & App Development"
        }
        description={
          blog?.blogSeoMetaDescription?.content[0]?.content[0]?.value ||
          "GatikAI Technologies Blog | Insights on AI, IoT, Web & App Development"
        }
        url={blog?.blogTitle}
        keywords={
          blog?.blogCategory?.content[0]?.content[0]?.value ||
          "GatikAI Technologies Blog | Insights on AI, IoT, Web & App Development"
        }
      />
      <Navbar />
      <Header
        title={blog?.blogTitle}
        image={blog?.blogImage?.fields?.file?.url}
      />
      <Container sx={{ mb: 8 }}>
        <Box sx={{ mt: 5, mb: 3 }}>
          <Card
            sx={{
              borderRadius: "2px",
              boxShadow: "0 1px 40px rgba(0, 0, 0, 0.1)",
            }}
          >
            <CardContent>
              <Typography
                variant="h5"
                sx={{
                  mb: 2,
                  fontWeight: "bold",
                  fontSize: "20px",
                  letterSpacing: "1.5px",
                  fontFamily: "Poppins, sans-serif",
                  color: "rgba(9, 8, 8, 0.68)",
                }}
              >
                {blog?.blogTitle}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  mb: 3,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CalendarMonthOutlinedIcon sx={{ mr: 1, color: "#f68f5c" }} />{" "}
                {formattedDate}
              </Typography>
            </CardContent>
            <CardMedia
              component="img"
              height="500"
              image={blog?.blogImage?.fields?.file?.url}
              alt={blog?.blogImage?.fields?.file?.title}
            />
            <CardContent>
              <Box
                sx={{
                  mb: 5,
                  letterSpacing: "1.5px",
                  lineHeight: "1.5rem",
                  fontWeight: "500",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                <RichText content={blog?.blogDescription} />
              </Box>
              <Divider sx={{ mt: 1, backgroundColor: "rgba(0, 0, 0, 0.6)" }} />
              <Box sx={{ mt: 5, mb: 5 }}>
                <Typography
                  variant="h5"
                  sx={{
                    mb: 3,
                    fontWeight: "bold",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  About The Author
                </Typography>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: { xs: "1fr", md: "1fr 2fr" },
                    gap: 4,
                    alignItems: "center",
                  }}
                >
                  {/* Author Image */}
                  <img
                    src={assets.BlogAuthor}
                    alt="Author Image"
                    style={{
                      width: "100%",
                      borderRadius: "50%",
                      objectFit: "cover",
                      maxWidth: "150px",
                      margin: "0 auto",
                    }}
                  />

                  {/* Author Details */}
                  <Box>
                    <Typography
                      variant="h6"
                      sx={{
                        mb: 2,
                        fontWeight: "bold",
                        fontFamily: "Poppins, sans-serif",
                        color: "rgba(9, 8, 8, 0.68)",
                        letterSpacing: "1.5px",
                        lineHeight: "1.5rem",
                      }}
                    >
                      Abhyuday Mathur
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        mb: 3,
                        lineHeight: "1.8",
                        fontFamily: "Poppins, sans-serif",
                        color: "rgba(9, 8, 8, 0.68)",
                        letterSpacing: "1.5px",
                        // lineHeight: "1.5rem",
                      }}
                    >
                      My name is <b>Abhyuday Mathur</b>, and I am the Co-Founder
                      and Managing Director of <b>GatikAI Technologies</b>, a
                      leading digital marketing and{" "}
                      <b>
                        <Link
                          to="https://gatiktech.com/"
                          style={{ color: "#333e4b", textDecoration: "none" }}
                        >
                          Website development company based in Jaipur, India.
                        </Link>{" "}
                      </b>
                      I am passionate about leveraging cutting-edge technologies
                      and innovative solutions to drive growth across various
                      industries. As a dedicated entrepreneur in the IT sector,
                      my mission is to empower businesses with the latest
                      insights and strategies to stay ahead in an ever-evolving
                      digital landscape.
                    </Typography>

                    <Button
                      variant="outlined"
                      sx={{
                        // mt: 4,
                        color: Colors.DarkGray,
                        borderRadius: 8,
                        border: "1px solid #333",
                        "&:hover": {
                          backgroundColor: "#fd7e14",
                          color: Colors.WHITE,
                          border: "1px solid #f68f5c",
                        },
                      }}
                    >
                      <Link
                        to={"/blogs"}
                        style={{
                          color: "inherit",
                          textDecoration: "none",
                          letterSpacing: "2px",
                        }}
                      >
                        {" "}
                        See More Blogs
                      </Link>
                    </Button>
                  </Box>
                </Box>

                {/* Icons */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 2,
                    mt: 4,
                    flexWrap: "wrap",
                  }}
                >
                  {/* LinkedIn Icon */}
                  <IconButton
                    href="https://www.linkedin.com/company/gatikai-technologies/home"
                    target="_blank"
                    sx={{ color: "#0077B5" }}
                  >
                    <LinkedInIcon
                      sx={{ fontSize: { xs: "30px", md: "40px" } }}
                    />
                  </IconButton>

                  {/* Facebook Icon */}
                  <IconButton
                    href="https://www.facebook.com/people/GatikAi-Technologies/61553559459205/?mibextid=rS40aB7S9Ucbxw6v"
                    target="_blank"
                    sx={{ color: "#1877F2" }}
                  >
                    <FacebookIcon
                      sx={{ fontSize: { xs: "30px", md: "40px" } }}
                    />
                  </IconButton>

                  {/* Instagram Icon */}
                  <IconButton
                    href="https://www.instagram.com/gatik_tech?igsh=emI4ZXg0OTE3bWVn&utm_source=qr"
                    target="_blank"
                    sx={{
                      color: "#E4405F",
                    }}
                  >
                    <InstagramIcon
                      sx={{ fontSize: { xs: "30px", md: "40px" } }}
                    />
                  </IconButton>

                  {/* YouTube Icon */}
                  <IconButton
                    href="https://www.youtube.com/channel/UCkW7JC4MPt3CPUc3-sQf77w"
                    target="_blank"
                    sx={{ color: "#FF0000" }}
                  >
                    <YouTubeIcon
                      sx={{ fontSize: { xs: "30px", md: "40px" } }}
                    />
                  </IconButton>

                  {/* Website Icon */}
                  <IconButton
                    href="https://gatiktech.com/"
                    target="_blank"
                    sx={{ color: "#f68f5c" }}
                  >
                    <LanguageIcon
                      sx={{ fontSize: { xs: "30px", md: "40px" } }}
                    />{" "}
                  </IconButton>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Container>

      <Footer />
    </>
  );
};

export default SingleBlog;
