import React, { useState, useEffect, useRef } from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import { Colors, Fontfamily } from "../common";
import { Link } from "react-router-dom";

const Header = ({ 
  title, 
  description, 
  image, 
  placeholder, 
  link, 
  target = "",
  buttonText = "Join the Test Series",
  buttonLink = "", 
  overlayColor = "rgba(0, 0, 0, 0.4)", 
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const imgRef = useRef(null);

  useEffect(() => {
    if ("IntersectionObserver" in window) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsLoaded(true);
            observer.disconnect();
          }
        },
        { threshold: 0.1 }
      );

      if (imgRef.current) observer.observe(imgRef.current);

      return () => observer.disconnect();
    } else {
      setIsLoaded(true);
    }
  }, []);

  return (
    <Box
      sx={{
        backgroundImage: `url(${isLoaded ? image : placeholder})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: { xs: "scroll", md: "fixed" },
        // height: { xs: "90vh", md: "100vh" },
        height: { xs: "980px", md: "980px" },
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* Overlay */}
      <Box
        sx={{
          width: "100%",
          height: "100%",
          position: "absolute",
          backgroundColor: overlayColor,
        }}
      />

      {/* Content */}
      <Grid
        container
        direction="column"
        alignItems="start"
        textAlign="start"
        sx={{
          position: "relative",
          color: "#fff",
          px: { xs: 2, md: 5 },
          mr: { xs: 2, md:5},
          ml: { xs: 2, md:5},
        }}
      >
        {/* Title */}
        <Typography
          variant="h1"
          sx={{
            fontWeight: "900",
            fontSize: { xs: "35px", sm: "44px", md: "60px" },
            fontFamily: Fontfamily.playfairDisplay,
            letterSpacing: "2px",
            maxWidth: "800px",
          }}
        >
          {title}
        </Typography>

        {/* Subtext */}
        <Typography
          // variant="h6"
          sx={{
            fontSize: { xs: "16px", sm: "20px", md: "18px" },
            fontWeight: "bold",
            letterSpacing: { xs: "1.2px", md: "1.8px" },
            mt: 2,
          }}
        >
          {description}
        </Typography>

        {/* Call-To-Action Button */}
        {link && (
          <Button
            component={Link}
            to={buttonLink || link}
            target={target}
            rel="noopener noreferrer"
            variant="contained"
            sx={{
              mt: 4,
              background: "linear-gradient(135deg, #FF716E 0%, #FF5733 100%)", 
              color: Colors.WHITE,
              borderRadius: "50px",
              fontSize: "18px",
              fontWeight: "bold",
              textTransform: "none",
              padding: "8px 20px",
              "&:hover": {
                background: "linear-gradient(135deg, #FF716E 0%, #F68f5c 100%)",
              },
            }}
          >
            {buttonText}
          </Button>
        )}
      </Grid>

      {/* Hidden Image for Lazy Loading */}
      <img
        ref={imgRef}
        src={image}
        alt="background"
        style={{ display: "none" }}
        onLoad={() => setIsLoaded(true)}
      />
    </Box>
  );
};

export default Header;
