import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  Card,
  CardContent,
  Avatar,
  Paper,
  InputAdornment,
  CircularProgress,
  CardMedia,
} from "@mui/material";
import {
  AccountCircle,
  Email,
  Phone,
  ChatBubble,
  AttachFile,
} from "@mui/icons-material";
import { message } from "antd";
import axios from "../axiosInstance";
import assets from "../assets";
import { FontWeight, FontSize, Colors, Fontfamily } from "../common";
import PageTitle from "../Components/PageTitle";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import career from "../assets/images/career.jpg";
import Header from "../Components/Header";

function Career() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    countryCode: "91",
    messages: "",
    file: null,
  });

  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      setFormData({ ...formData, file });
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    // Form validation
    if (!formData.name) {
      message.error("Please enter your name.");
      setLoading(false);
      return;
    }
    if (!formData.email) {
      message.error("Please enter your email.");
      setLoading(false);
      return;
    }
    if (!formData.phone) {
      message.error("Please enter your phone number.");
      setLoading(false);
      return;
    }
    if (!formData.messages) {
      message.error("Please write your skill set.");
      setLoading(false);
      return;
    }
    const formDataToSend = new FormData();
    formDataToSend.append("name", formData.name);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("phone", formData.countryCode + formData.phone);
    formDataToSend.append("messages", formData.messages);
    if (formData.file) {
      formDataToSend.append("file", formData.file);
    }

    try {
      await axios.post("/api/v1/file/send-job-email", formDataToSend, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      message.success("Thank you for your application! We're excited to review it and will be in touch shortly!");

      // Reset form
      setFormData({
        name: "",
        email: "",
        phone: "",
        countryCode: "+91",
        discussion: "",
        file: null,
      });
      setFileName("");
    } catch (error) {
      message.error("Failed to submit form. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const features = [
    "We are a mission-driven company.",
    "You get the advantage of our mandatory training and up skilling opportunities.",
    "Our generous leave policy gives you time to recharge and focus on what matters most.",
    "Enjoy a healthy work-life balance with our five-day workweek.",
    "We strive for a brighter, more innovative future and want you to be part of it.",
    "We have created a workplace where everyone feels valued and heard.",
  ];

  const FeatureCard = ({ number, text }) => {
    return (
      <Card
        sx={{
          textAlign: "center",
          borderRadius: 2,
          boxShadow: "5px 8px 5px 8px #f2f2f2",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 1,
          height: { md: "220px", xs: "auto" },
        }}
      >
        {/* Circular Number Styling */}
        <Box
          sx={{
            mt: 3,
            mb: -3,
            width: 50,
            height: 50,
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "linear-gradient(to bottom, #f2f2f2, #e0e0e0)",
            border: "2px solid #ccc",
          }}
        >
          <Typography variant="h6" fontWeight="bold" color="#FF716E">
            {number}
          </Typography>
        </Box>

        <CardContent>
          <Typography
            variant="body1"
            sx={{
              mt: 2,
              letterSpacing: "1px",
              fontFamily: Fontfamily.poppins,
            }}
          >
            {text}
          </Typography>
        </CardContent>
      </Card>
    );
  };

  const teamMembers = [
    {
      name: "Abhishek Sharma",
      role: "Sr.React Native Developer",
      image: assets.Team1,
      text: "I’ve been with GatikAI Technologies for three years, contributing to mobile app development and integrating innovative solutions. Working alongside a talented team, I’ve strengthened my technical skills, particularly in React Native and Flutter, and played a key role in enhancing user experiences and ensuring product quality.",
    },
    {
      name: "Sanjeev Kumar",
      role: "Full Stack Developer",
      image: assets.Team2,
      text: "I started as a MERN Stack Developer at GatikAI Technologies and evolved into a Full Stack Developer, expanding my expertise in Next.js, AWS, SEO, and MySQL. Thanks to the support and opportunities here, my passion has grown, and my career has been shaped by the inspiring work environment.",
    },
    {
      name: "Bhavna Sharma",
      role: "React Native Developer",
      image: assets.Team3,
      text: "Starting my career at GatikAI Technologies has been an incredible journey. I’ve learned so much here—both technically and professionally—while working on cutting-edge AI projects. The supportive environment and hands-on experience have helped me grow, and I’m grateful for the opportunities to solve real-world problems alongside a talented team.",
    },
    {
      name: "Shobhit Sharma",
      role: "Python Trainee",
      image:assets.Team4,
      text: "Since joining GatikAI Technologies as a Python Trainee, I have had the opportunity to enhance my skills in Python development and backend systems. My role has also allowed me to grow in problem-solving, code efficiency, and teamwork.",
    },
  ];

  return (
    <>
      <Navbar />
      <PageTitle
        title="Careers at GatikAI Technologies | Join Our Team"
        description="Explore exciting career opportunities at GatikAI Technologies. Join a dynamic and innovative team where your skills can thrive and make a difference in the tech world."
        url="career"
        keywords="careers, GatikAI Technologies, job openings, career opportunities, IT jobs, join our team, tech careers, hiring"
      />
      <Header
        title="Join Our Team"
        description="Join us in our mission to build a team of highly skilled and recognized developers"
        image={career}
      />
      <Box
        sx={{
          minHeight: "auto",
          mt: 5,
          ml: { xs: 2, md: 10 },
          mr: { xs: 2, md: 10 },
          mb: 8,
        }}
      >
        <Typography
          variant="h2"
          sx={{
            mt: 4,
            letterSpacing: "1.5px",
            fontWeight: FontWeight.bold,
            color: Colors.charcoalgray,
            fontSize: FontSize.thirty,
            fontFamily: Fontfamily.poppins,
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
          }}
        >
          Why join GatikAI Technologies?
        </Typography>
        <Typography
          variant="body1"
          sx={{
            mt: 2,
            letterSpacing: "1.5px",
            fontFamily: Fontfamily.poppins,
          }}
        >
          Here are a few reasons why you should join us
        </Typography>
        <Grid container spacing={3} mt={3} justifyContent="center">
          {features.map((text, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <FeatureCard number={index + 1} text={text} />
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box
        sx={{
          minHeight: "auto",
          mt: 5,
          ml: { xs: 2, md: 10 },
          mr: { xs: 2, md: 10 },
          mb: 8,
        }}
      >
         <Typography
          variant="h2"
          sx={{
            mt: 4,
            mb:8,
            letterSpacing: "1.5px",
            fontWeight: FontWeight.bold,
            color: Colors.charcoalgray,
            fontSize: FontSize.thirty,
            fontFamily: Fontfamily.poppins,
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
          }}
        >
          Voices of GatikAI
        </Typography>
        <div className="py-5 team4">
          <>
            <Grid container spacing={4}>
              {teamMembers.map((member, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <Card
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
                      borderRadius: "10px",
                      overflow: "hidden",
                      transition: "transform 0.3s ease",
                      "&:hover": { transform: "scale(1.05)" },
                    }}
                  >
                    <CardMedia
                      component="img"
                      sx={{
                        height: 300,
                        width: "100%",
                        objectFit: "inherit !important",
                        display: "block",
                        margin: "auto",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                      }}
                      image={member.image}
                      alt={member.name}
                    />
                    <CardContent sx={{ flexGrow: 1, textAlign: "center" }}>
                      <Typography variant="h5" 
                      gutterBottom  sx={{
                    textAlign: "center",
                    letterSpacing: "1.5px",
                    fontWeight: FontWeight.bold,
                    color: Colors.charcoalgray,
                    fontFamily: Fontfamily.poppins,
                    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
                  }}>
                        {member.name}
                      </Typography>
                      <Typography variant="h6" color="textSecondary" mb={3}  
                  sx={{
                    textAlign: "center",
                    mb: 1,
                    fontWeight: 500,
                    letterSpacing: "1.5px",
                    color: Colors.charcoalgray,
                    fontSize: FontSize.fourteen,
                    fontFamily: Fontfamily.poppins,
                  }}>
                        {member.role}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          textAlign: "start" ,
                          mt: 2,
                          letterSpacing: "1.5px",
                          fontFamily: Fontfamily.poppins,
                          color: "#7a7a7a",
                          fontSize: FontSize.fifteen,
                        }}
                        paragraph
                      >
                        {member.text}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        </div>
      </Box>

      <Box
        sx={{
          minHeight: "auto",
          mt: 5,
          ml: { xs: 2, md: 10 },
          mr: { xs: 2, md: 10 },
          mb: 8,
        }}
      >
        <Typography
          variant="h2"
          sx={{
            mt: 4,
            letterSpacing: "1.5px",
            fontWeight: FontWeight.bold,
            color: Colors.charcoalgray,
            fontSize: FontSize.thirty,
            fontFamily: Fontfamily.poppins,
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
          }}
        >
          Are you ready to apply?
        </Typography>

        <Box
          sx={{ mt: 8, mb: 10, }}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Paper elevation={2} sx={{ p: 4, width: "100%", borderRadius: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    // label="Enter your full name"
                    placeholder="Enter your full name *"
                    variant="outlined"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    InputLabelProps={{
                      sx: {
                        "&.Mui-focused": {
                          color: "#f68f5c",
                        },
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle sx={{ color: "#000" }} />
                        </InputAdornment>
                      ),
                      sx: {
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#f68f5c",
                        },
                        "&:hover .MuiOutlinedInput": {
                          borderColor: "gray",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "10px", // Rounded corners
                        },
                      },
                    }}
                    sx={{ mb: 3 }}
                  />
                  <TextField
                    fullWidth
                    // label="Company email"
                    placeholder="E-mail *"
                    variant="outlined"
                    name="email"
                    required
                    value={formData.email}
                    onChange={handleChange}
                    InputLabelProps={{
                      sx: {
                        "&.Mui-focused": {
                          color: "#f68f5c",
                        },
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Email sx={{ color: "#000" }} />
                        </InputAdornment>
                      ),
                      sx: {
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#f68f5c",
                        },
                        "&:hover .MuiOutlinedInput": {
                          borderColor: "gray",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "10px", // Rounded corners
                        },
                      },
                    }}
                    sx={{ mb: 3 }}
                  />
                  <Grid container spacing={1}>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        label="Code"
                        name="countryCode"
                        required
                        value={formData.countryCode}
                        onChange={handleChange}
                        type="number"
                        sx={{
                          "& input[type='number']": {
                            MozAppearance: "textfield",
                            "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                              {
                                display: "none",
                              },
                          },
                        }}
                        InputLabelProps={{
                          sx: {
                            "&.Mui-focused": {
                              color: "#f68f5c",
                            },
                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              sx={{ color: "#000" }}
                              position="start"
                            >
                              +
                            </InputAdornment>
                          ),
                          sx: {
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#f68f5c",
                            },
                            "&:hover .MuiOutlinedInput": {
                              borderColor: "gray",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderRadius: "10px", // Rounded corners
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <TextField
                        fullWidth
                        // label="Phone number "
                        placeholder="Phone Number *"
                        variant="outlined"
                        name="phone"
                        required
                        value={formData.phone}
                        onChange={handleChange}
                        type="number"
                        sx={{
                          "& input[type='number']": {
                            MozAppearance: "textfield",
                            "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                              {
                                display: "none",
                              },
                          },
                        }}
                        InputLabelProps={{
                          sx: {
                            "&.Mui-focused": {
                              color: "#f68f5c",
                            },
                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Phone sx={{ color: "#000" }} />
                            </InputAdornment>
                          ),
                          sx: {
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#f68f5c",
                            },
                            "&:hover .MuiOutlinedInput": {
                              borderColor: "gray",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderRadius: "10px", // Rounded corners
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    required
                    placeholder="Write your skill set?"
                    // label="What would you like to discuss?"
                    variant="outlined"
                    multiline
                    rows={3}
                    name="messages"
                    value={formData.messages}
                    onChange={handleChange}
                    sx={{ mb: 3 }}
                    InputLabelProps={{
                      sx: {
                        "&.Mui-focused": {
                          color: "#f68f5c",
                        },
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment sx={{ mb: 5.3 }} position="start">
                          <ChatBubble sx={{ color: "#000" }} />
                        </InputAdornment>
                      ),
                      sx: {
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#f68f5c",
                        },
                        "&:hover .MuiOutlinedInput": {
                          borderColor: "gray",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "10px", // Rounded corners
                        },
                      },
                    }}
                  />

                  {/* File Upload */}
                  <Box
                    sx={{
                      border: "2px dashed gray",
                      borderRadius: 2,
                      textAlign: "center",
                      p: 2,
                      cursor: "pointer",
                      "&:hover": { backgroundColor: "#f9f9f9" },
                    }}
                    InputLabelProps={{
                      sx: {
                        "&.Mui-focused": {
                          color: "#f68f5c",
                        },
                      },
                    }}
                  >
                    <input
                      type="file"
                      style={{ display: "none" }}
                      id="file-upload"
                      onChange={handleFileUpload}
                      InputLabelProps={{
                        sx: {
                          "&.Mui-focused": {
                            color: "#f68f5c",
                          },
                        },
                      }}
                    />
                    <label htmlFor="file-upload">
                      <AttachFile sx={{ fontSize: 30, color: "gray", cursor:"pointer"  }} />
                      <Typography variant="body1" sx={{ color: "gray" }}>
                       { fileName || "Browse to drop file here"}
                      </Typography>
                    </label>
                  </Box>
                </Grid>

                {/* Submit Button */}
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={loading}
                    sx={{
                      background:
                        "linear-gradient(135deg, #FF716E 0%, #FF5733 100%)",
                      color: "#fff",
                      mt: 2,
                      py: 1.5,
                      fontSize: "16px",
                      fontWeight: "bold",
                      borderRadius: "6px",
                      "&:hover": {
                        background:
                          "linear-gradient(135deg, #FF716E 0%, #F68f5c 100%)",
                      },
                    }}
                  >
                    {loading ? (
                      <CircularProgress size={24} sx={{ color: "#fff" }} />
                    ) : (
                      "Apply now"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Box>

      </Box>

      <Footer />
    </>
  );
}

export default Career;
